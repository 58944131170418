import SubdivisionSelect from './SubdivisionSelect'
import PersonLanguages from './PersonLanguages.vue'
import PersonLanguageStore from './PersonLanguageStore'

export default {
  components: { SubdivisionSelect, PersonLanguages },
  mixins: [PersonLanguageStore],
  data: {
    mobile: undefined,
    home_phone: undefined,
    work_phone: undefined,
    address_country: undefined,
    birth_country: undefined
  }
}
