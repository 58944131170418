<template lang='haml'>
  %calm-select(v-if="firstInitDone" :name="name" :options="subdivisionsToDisplay" v-model="internalValue" :loading="subdivisionsToDisplay.length == 0")
</template>

<script>
import { axios, handleAjaxError } from 'helpers/core/ajax-utils'
import Vue from 'vue'

export default {
  props: ['countryCode', 'value', 'name'],
  data() {
    return {
      subdivisionsByCountry: {},
      internalValue: undefined,
      firstInitDone: false
    }
  },
  computed: {
    subdivisionsToDisplay() {
      return this.subdivisionsByCountry[this.countryCode] || []
    }
  },
  watch: {
    countryCode(newVal) {
      if (this.firstInitDone) this.internalValue = ' ' // if not first init, reset the value
      this.downloadSubdivisionsForCountry(newVal)
    }
  },
  methods: {
    downloadSubdivisionsForCountry(country) {
      if (country && !this.subdivisionsByCountry[country]) {
        Vue.set(this.subdivisionsByCountry, country, []) // empty it so we don't run multiple download request
        axios.get(this.$routes.subdivisions_for_country_path({ code: country }))
          .then((result) => {
            const { data } = result
            Vue.set(this.subdivisionsByCountry, country, data)
            this.firstInitDone = true
          }).catch((error) => handleAjaxError(error, this))
      }
    }
  },
  mounted() {
    this.internalValue = this.value
    this.downloadSubdivisionsForCountry(this.countryCode)
  }
}
</script>

<style lang='scss' scoped>

</style>
